@import '../../../styles/config.scss';

.root {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    overflow: hidden;
}

.left {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, minmax(25%, 25%));
    grid-template-rows: repeat(10, minmax(25%, 25%));
    grid-gap: 0;
    width: 50%;

    @include _tablet {
        display: none;
    }
}
.block {
    background-color: #CDCDCD;
}
.block_2 {
    @extend .block;
    opacity: .25;
}
.block_1{
    @extend .block;
    opacity: .125;
}
.block_3 {
    @extend .block;
    opacity: .40;
}
.block_4 {
    @extend .block;
    opacity: .5;
}
.right {
    display: flex;
    flex: 1;
    width: 50%;
    align-items: center;
    justify-content: center;
    @include _tablet {
        padding: 10px;
    }
}
.vars {
    position: absolute;
    top: 50%;
    transform: translate(10%, -50%);
    right: 0;
    max-width: 90%;
    svg {
        max-width: 100%;
    }
}
.form {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}
.inputs {
    margin-bottom: 23px;
    position: relative;
}
.problem {
    margin-top: 32px;
    text-align: center;
    cursor: pointer;
    span {
        margin-right: 8px;
        color: $color-text-grey;
        font-weight: 500;
        font-size: 16px;
    }
}

.login {
    border: 1px solid #E3E6EE;
    border-bottom: none;
    border-radius: 2px 2px 0 0 ;
}
.pass {
    border-radius: 0 0 2px 2px;
    border: 1px solid #E3E6EE;
}

.error {
    position: absolute;
    color: $color-primary;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}
