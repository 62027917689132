@import '../../../styles/config.scss';

.select {
    border: none;
    background: transparent;
    min-width: 150px;
    position: relative;
    padding: 0 16px;

    &:active {
        box-shadow: none;
    }
    
}

.label {
    // padding: 0 16px;
}

.options {
    cursor: pointer;
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    border-top: 1px solid $color-grey;
    padding: 5px 0;
    &:not(:first-child){
        margin-top: 10px;
    }
}

.drop {
    position: absolute;
    top: 100%;
    left: -1px;
    background-color: #fff;
    // box-shadow: 0 0 3px rgba(0,0,0,.2);
    border-radius: 0 0 5px 5px;
    padding: 11px 16px;
    z-index: 10;
    border: 1px solid $color-text_grey;
    border-top: none;
    text-align: left;
    box-sizing: border-box;

}
.open {
    // box-shadow: 0 0 3px rgba(0,0,0,.2);
    border: 1px solid $color-text_grey;
    border-bottom: none;
    box-sizing: border-box;
}

.bgcOdd {
    background-color: $color-grey-2;
}

.topLabel {
    display: flex;
    align-items: center;
}

.mark {
    margin-left: 16px;
}

.activeMark {
    transform: rotate(180deg);
}

.color {
    width: 12px;
    height: 12px;
    // margin: 0 16px;
}

.colorLabel {
    padding: 0 16px;
}

.isWhite {
    border: 1px solid $color-black;
}

.checkFrame {
    display: flex;
    align-items: center;
    margin-left: 16px;
    span {
        margin-left: 8px;
    }
}