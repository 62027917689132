@import "/src/styles/config.scss";

.rowBody {
    border-bottom: 1px solid $color-grey;
}
.rowBody .cellBody {
    border: none;
    &:first-child {
        padding-left: 16px;
    }
}
.flexBlock {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    span {
        margin-left: 6px;
        margin-right: 6px;
    }
}

.open {
    transform: rotate(180deg);
}

.openFilial {
    background-color: $color-light-blue;
}

/**
* User Item
*/
.userItemRow {
    width: 100%;
    border-bottom: 1px solid $color-grey;
}

.userItemRow .userCell {
    border: none;
}

.nameWrapper {
    display: flex;
    width: 100%;
    // margin-left: 50px;
}

.leftNumber {
    width: 100px;
}

.checkBox {
    width: 25px;
    display: flex;
    justify-content: flex-start;
}

.userCell {
    text-align: left;
    &:last-child {
        padding: 0 12px;
    }
    &:first-child {
        padding: 0 12px;
    }
}

.userItemRow .userCell.activeUser {
    background-color: $color-light-blue;
    border-bottom: 1px solid $color-blue;
    border-top: 1px solid $color-blue;
}

.userName {
    cursor: pointer;

    &:hover {
        color: $color-blue;
    }
}

// USER TABLE

.userTable {
    width: 100%;
    max-height: 400px;

    tbody {
        width: 100%;
    }
}

.cell {
    vertical-align: middle;
    &:first-child {
        padding-left: 16px;
    }
    span {
        font-weight: 500;
    }
}
.cell_1 {
    min-width: 100px;
    width: 150px;
    height: 40px;
    padding-left: 0;
}
.cell_2 {
    width: 300px;
}
.cell_3 {
    width: 200px;
    // width: 250px;
}
.cell_4 {
    width: 150px;
}
.number {
    &:hover {
        color: $color-blue;
    }
}

.userHeaderRow {
    background-color: rgb(247, 246, 246);
    padding-left: 0;
}

.userHeaderRow td:first-child {
    padding-left: 0;
}

.staffTableBody {
    max-height: 500px;
    width: 100%;
    overflow: hidden;
}

.changeBtn {
    height: 28px;
    border-radius: 4px;
    background-color: $color-light-blue;
    border-color: $color-blue;
    color: $color-blue;
    padding: 0 8px;
    &:hover {
        span {
            color: $color-blue;
        }
    }
}
