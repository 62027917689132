@import '../../../styles/config.scss';

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;


}

.button {
    margin-top: 24px;
    height: 32px;
    border: 1px solid $color-grey;
    color: $color-black;
    padding: 0 16px;
    font-size: 12px;

    &:hover {
        color: $color-black;
    }
}