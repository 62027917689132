@import '../../../../../styles/config.scss';

.photoInput {
    display: none;
}

.photoWrapper {
    width: 210px;
    height: 300px;
    background-color: #fff;
    margin-bottom: 14px;
    object-fit: fill;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.photo {
    max-width: 100%;
    object-fit: contain;
}

.button {
    height: 32px;
    border: 1px solid $color-grey;
    color: $color-black;
    padding: 0 16px;
    font-size: 12px;

    &:hover {
        color: $color-black;
    }
}
