@import '../../../styles/config.scss';

.root {
    padding: 0 18px;
    height: 32px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    transition: .2s;
    cursor: pointer;

    span {
        font-size: 12px;
    }

    &:hover {
        box-shadow: 0 0 6px rgba(0,0,0,.2);
    }
    &:active {
        box-shadow: 0 0 2px rgba(0,0,0,.2);
    }
}
.disabled {
    span {
        color: $color-text-grey;
    }
}

.icon {
    margin-right: 6px;
    display: flex;
}

.active {
    border:1px solid $color-blue;
}