@import "/src/styles/config.scss";

.label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: $color-text_grey;
    margin-bottom: 4px;
}

.hours {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 0.2rem;
    max-width: 300px;
    @media (min-width: 1720px) {
        max-width: 400px;
        gap: 5px 0px;
    }
}

.hourItem {
    display: flex;
    justify-content: center;
    padding: 4px 0;
    width: 23px;

    background-color: #fff;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: $color-text_grey;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 4px;
    }
    @media (min-width: 1720px) {
        width: 20px;
    }
}

.cellBlock {
    display: flex;
    margin: 6px 0;
}

.cellNav {
    display: flex;
    margin-left: 16px;

    div {
        margin-right: 4px;
    }
}

.navBtn {
    max-width: 32px;
    justify-content: center;
}

.downIcon {
    transform: rotate(90deg);
}

.buttons {
    display: flex;
}

.clearAllButton {
    width: 253px;
    margin-bottom: 4px;
    justify-content: center;
}
.btnLeft {
    margin-right: 8px;
}

.checkAll {
    margin-bottom: 16px;
}

.activeBtn {
    border: 1px solid $color-primary;
}

/**
*   top panel
*/

.topRoot {
    padding: 20px 16px 10px;
}

.item {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.items {
    margin-top: 12px;
    border-bottom: 1px solid $color-grey;

    &:not(:last-child) {
        border-bottom: 1px solid $color-grey;
    }
}

.bottomTop {
    position: relative;
    border-bottom: none;
    button {
        padding: 0 5px;
    }
}

.leftItem {
    width: 40%;
    color: $color-text_grey;
    // margin-bottom: 16px;
}

.rightItem {
    width: 60%;
}

.enterExitBlock {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.enterExitItem {
    display: flex;
    align-items: center;
    margin-right: 12px;

    span {
        margin-right: 8px;
    }
}

.timeBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.checksBlock {
    display: flex;
    flex-wrap: wrap;
}

.saveBtnTime {
    cursor: pointer;
    color: $color-text_grey;
}

.emptyBlock {
    color: $color-text_grey;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.changeButtons {
    margin-top: 20px;
}

.changeButton {
    width: 100%;
    background-color: #fff;
    border: 1px solid $color-text_grey;
    border-radius: 4px;
    height: 32px;
    color: $color-text_grey;
    font-size: 12px;
    font-weight: 300;
    padding: 0 16px;
    margin-bottom: 10px;
}

.activeBtnTime {
    border: 1px solid $color-blue;
    background-color: #e5f0ff;
    color: $color-blue;
}

.wideBtn {
    padding: 0 5px;
    margin: 0 -20px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/**
* MODAL
*/
.modal {
    padding-top: 20px;
    max-width: 800px;
}

.modalItemFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: block;
        width: 100px;
        color: $color-text_grey;
        font-size: 12px;
    }
}

.btnBlockFilter {
    display: flex;
    align-items: center;
}

.chooseBtn {
    width: 50%;
    height: 40px;
    margin-top: 0;
    // background-color: $color-grey_2;
    // border-color: $color-grey_2;
}

.activeBtn {
    background-color: $color-grey_2;
    border-color: $color-grey_2;
}

.itemRight {
    width: 70%;
    display: flex;
    align-items: center;
    flex: 1;
}
.itemRightInner {
    flex: 1;
}

.btnBlockFilter {
    width: 70%;
}

.datePicker {
    width: 100%;
}

.printBtn {
    border: 1px solid $color-grey;
    height: 40px;
    justify-content: center;
    margin-top: 24px;
}

.rightBlockWrapper {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #000000;
    align-items: end;
}

.rightBlock {
    display: flex;
    margin-top: 6px;
    gap: 5px 8px;
    flex-wrap: wrap;
    button:not(:last-child) {
        margin-right: 17px;
    }
}

.dropZone {
    width: 368px;
    height: 201px;
    background: #ffffff;
    border: 1px dashed #c4c4c4;
}

.modalDrop {
    width: 400px;
    height: 321px;
    background: #ffffff;
    border-radius: 4px;
}

.btnSend {
    outline: none;
    border: none;
    text-align: center;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #000000;
    background: #ffffff;
    border: 1px solid #e3e6ee;
    border-radius: 4px;
}

// BUTTONS
.changeBtn {
    height: $element_height;
    border-radius: 4px;
    background-color: $color-light-blue;
    border-color: $color-blue;
    color: $color-blue;
    padding: 0 4px;
    flex: 1;
    height: 32px;
    margin-right: 0 !important;
    &:hover {
        span {
            color: $color-blue;
        }
    }
}

.finalBtn {
    height: $element_height;
    border-radius: 4px;
    background-color: $color-black;
    border-color: $color-black;
    color: #fff;
    padding: 0 8px;
    flex: 1;
    height: 32px;

    span {
        color: #fff !important;
    }
}

.finalText {
    margin: 5px auto 0px auto;
    flex: 1;
    text-align: center;
}
