@import '../../../styles/config.scss';
.title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 25px;
}
.info {
    color: $color-text_grey;
    margin-bottom: 12px;
}

.btnBlock {
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
}