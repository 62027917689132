@import '/src/styles/config.scss';

table td {
    vertical-align: middle ; 
    text-align: center;
    height: 40px;
    background-color: #fff;
    border: 1px solid $color-background;
    &:first-child {
        border-right: none;
        border-left: none;
        padding-left: 16px;
    }

    &:not(:nth-child(n+3)){
        text-align: left;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid $color-background;

    }
}

.cell_sticky_1 {
    position: sticky;
    border: 1px solid $color-background;
    border-width: 2px;
    left: 0
}
.cell_sticky_2 {
    position: sticky;
    left: 120px
}
.row {
    border-top: 1px solid $color-grey;
}

.mark {
    cursor: pointer;
    fill: $color-text_grey;
    margin-right: 16px;
}

.activeMark {
    fill: $color-black;
    transform: rotate(90deg);
}
.name {
    cursor: pointer;
    color: $color-text_grey;
}
.activeName {
    color: $color-black;
}

.isWeekend {
    color: $color-text_grey;
    background-color: $color-grey-2 !important;
}

.isCurrentDay {
    border-left: 2px solid ; 
    border-right: 2px solid;
}

.valueOt {
    color: red;
}
