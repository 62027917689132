@import '/src/styles/config.scss';

.tableStaff {
    border: none;
    width: 100%;
    max-height: 400px;

    tbody {
        width: 100%;
    }
}