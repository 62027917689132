@import "/src/styles/config.scss";

.modal {
  background-color: #f5f8ff;
  max-width: 400px;
  padding-top: 29px;
  max-height: 99vh;
  height: min-content;
  padding-bottom: 40px;
  overflow-y: auto;
}
.modalBody {
  padding: 0 70px;
}
.holder {
  margin-bottom: 32px;
}

.item {
  display: flex;
  align-items: center;
  height: 25px;
  margin: 7px 0;
  flex: 1;
  cursor: pointer;
}

.tableType {
  font-family: "Roboto";
  font-weight: normal;
  font-size: 17px;
  padding: 8px 0;
  margin: 0 16px;
}

.btnBlock {
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
}
.btnCancel {
  height: $element_height;
  border-color: $color-primary;
  color: $color-primary;
  margin-left: 16px;
  height: 45px;
  width: 130px;
  padding: 0 8px;
  border-radius: 4px;
}

.btn {
  height: $element_height;
  border-radius: 4px;
  background-color: $color-light-blue;
  border-color: $color-blue;
  color: $color-blue;
  height: 45px;
  width: 130px;
  padding: 0 8px;
  &:hover {
    span {
      color: $color-blue;
    }
  }
}
