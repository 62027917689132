@import '../../../../../../styles/config.scss';


.root {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.input {
    border: 1px solid $color-grey;
    border-radius: 4px;
    overflow: hidden;
    width: 480px;
}