@import '../../../../styles/config.scss';

.cell {
    min-width: 150px;
    text-align: start;
    vertical-align: middle;
    border-collapse: collapse;
    border-left: none;

    &:first-child {
        padding-left: 16px;
    }
}
.cell_1 {
    width: 30%;
}
.cell_2 {
    width: 30%;
}
.cell_3 {
    width: 70%;
    border: none;
}

.cell_inner {
    background-color: $color-grey_2;
    vertical-align: top;
}
thead{
    width: 100%;
}
.table{
    width: 100%;
    border-collapse: collapse;
    td{
        border: none;
    }
}

.header {
    background-color: #fff;
    width: 100%;
    margin-bottom: 1px;
    border-bottom: none;
}
.itemRow{
    height: 40px;
    padding: 13px 16px;
    border-collapse: collapse;
    border: 1px solid #E3E6EE;
    
}

// ITEM
.itemRow {
    background-color: #fff;
}
.activeItem .cell {
    background-color: $color-light-blue;
    border-bottom: 2px solid $color-blue;
    border-top: 2px solid $color-blue;
}
.cell_mark {
    display: flex;
    align-items: center;
    vertical-align: middle;
    height: 100%;
    cursor: pointer;
    span {
        margin-right: 6px;
    }
}

.activeMark {
    transform: rotate(180deg);
}

//INNER ITEM
.innerRow {
    background-color: $color-grey-2;
}
.wrapperItem {
    width: 100% ;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 10px;
    border-bottom: 1px solid $color-grey;
}

.holder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.left {

}

.right {
    padding-right: 10px;
}