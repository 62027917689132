@import '/src/styles/config.scss';
.buttons {
    display: flex;
}

.btnLeft {
    margin-right: 16px;
}

.line {
    height: 100%;
    width: 2px;
    background-color: rgb(38, 38, 38);

}

.printHeader {
    display: flex;

    img {
        margin-right: 24px;
    }
}

.userPrint {
    page-break-after:always;
}
.tablesBlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 80px;
}
.printTable {
   width: 49%;
   border: 1px solid rgb(95, 95, 95);
}

.printTable thead tr th {
    background:rgb(218, 218, 218);
    height: 35px;
    vertical-align: middle;
}

.printTable tr td {
   height: 20px;
}

.printing thead tr th {
    background:rgb(218, 218, 218);
    height: 35px;
    vertical-align: middle;
}

.name {
    font-weight: 500;
    font-size: 16px;
}
