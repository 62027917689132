@import '../../../../../styles/config.scss';

.root {
    display: flex;
}

.halfBlock {
    max-width: 50%;
    min-width: 500px;
    margin-top: 24px;

    &:not(:last-child){
        margin-right: 16px;
    }
}

.listWrapper {
    margin-top: 16px;
}

.button {
    margin-top: 24px;
    height: 32px;
    border: none;
    color: $color-black;
    padding: 0 16px;
    font-size: 12px;

    &:hover {
        color: $color-black;
    }
}

.note {
    color: $color-text_grey;
    margin-top: 8px;
    margin-bottom: 12px;
}