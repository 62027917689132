@import '/src//styles/config.scss';
.root {
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    width: $element-height;
    height: $element-height;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
    margin-left: 16px;

    &:hover {
        box-shadow: 0 0 6px rgba(0, 0, 0, .2);

        &::before {
            color: red;
        }
    }

    &::before{
        content: 'X';
        font-size: 20px;
        color: #c4c4c4;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;
    }
}