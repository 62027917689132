.container {
    width: 100%;
    height: 175px;


    /* stroke 8% */
    // border: 1px dashed rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: end;
    transition: .5s;
    cursor: pointer;

    p {
        margin-top: 11px;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        /* identical to box height, or 200% */

        display: flex;
        align-items: center;
        text-align: center;

        color: #000000;
    }

}

.contentInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
}

.activeContainer {
    transition: .5s;
    box-shadow: inset 0 0 52px 10px rgb(178, 231, 167);
}

.reject {
    transition: .5s;
    box-shadow: inset 0 0 52px 10px rgb(231, 167, 167);
}

.label {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
}

