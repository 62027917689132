@import '/src/styles/config.scss';

.input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $color-black;
    background-color: transparent;
    width: 100%;
}

.error {
    border-bottom: 1px solid $color-primary;
    color: $color-primary;
}

.disabled {
    border: none;
   
}