@import '../../../styles/config.scss';

.root {
    display: flex;
    width: 100vw;
    min-height: 100%;
    max-width: 100%;
}

.leftMenu {
    background-color: $color-menu_bgc;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: $menu-width;
    z-index: 9999;
    transition: .5s;

    @include _tablet() {
        transform: translateX(-100%);
    }
}

.openMenu {
    transform: translateX(0);
}

// .closeMenu {
//     transform: translateX(-100%);
// }

.burger {
    width: 50px;
    height: 30px;
    background-color: #fff;
    border-radius: 0 10px 10px 0;
    position: absolute;
    right: 500px;
    box-shadow: 2px 0 10px rgba(0, 0, 0, .4);
    transition: .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
        display: block;
        width: 30px;
        height: 2px;
        background-color: #000;
        position: relative;
        transition: .5s;

        &:before {
            display: block;
            content: '';
            width: 30px;
            height: 2px;
            background-color: #000;
            position: absolute;
            transform: translateY(-4px);
            transition: .5s;
        }

        &:after {
            display: block;
            content: '';
            width: 30px;
            height: 2px;
            background-color: #000;
            position: absolute;
            transform: translateY(4px);
            transition: .5s;
        }
    }

    @include _tablet() {
        right: -50px;
    }
}

.openBurger {
    span {
        width: 0px;

        &:before {
            transform: translateX(-10px) rotateZ(45deg);
            width: 20px;
        }

        &:after {
            transform: translateX(-10px) rotateZ(-45deg);
            width: 20px;
        }
    }
}

.content {
    margin-left: $menu-width;

    // display: flex;
    // flex: 1;

    background-color: $color-grey;
    // justify-content: space-between;

    @include _tablet() {
        margin-left: 0;
    }
}

.mainContent {
    margin-left: $menu-width;
    background-color: $color-grey;
    max-height: 100vh;
    overflow: auto;
    // padding: 24px;
    // padding-right: $right-component + 16px;
    flex: 1;
    width: 100%;
}

.top {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        margin-bottom: 56px;
    }
}

.bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menuItem {
    cursor: pointer;
    opacity: .5;
    margin-bottom: 30px;

}

.logOut {
    cursor: pointer;
}

.avatar {
    cursor: pointer;
}

.active {
    opacity: 1;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    // object-fit: fill;
    img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.rightHolder {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    width: $right-component;
}

.rightHolder {
   
    display: flex;
    transition: .5s;
    justify-content: flex-end;

    @include _tablet() {
        transform: translateX(calc(100% - 50px));
    }

}

.rightComponent {
    height: 100vh;
    overflow: scroll;
}

.openHolder {
    @include _tablet() {
        transform: translateX(0%);
    }
}

.openRight {
    @include _tablet() {
        box-shadow: -2px 2px 10px rgba(0, 0, 0, .4);
    }

}

.rightHolder {
    @include _tablet() {
        position: fixed;
        z-index: 999;
        right: 0;
    }
}

.rightMarkOpen {
    transition: .5s;
    // transform: translateX(100%);
    display: none;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 30px;
    background-color: $color-primary;

    span {
        display: block;
        width: 30px;
        height: 2px;
        background-color: #000;
        position: relative;
        transition: .5s;

        &:before {
            display: block;
            content: '';
            width: 30px;
            height: 2px;
            background-color: #000;
            position: absolute;
           transform: translateY(-4px);
           transition: .5s;
        }
        &:after {
            display: block;
            content: '';
            width: 30px;
            height: 2px;
            background-color: #000;
            position: absolute;
           transform: translateY(4px);
           transition: .5s;
        }
    }

    @include _tablet() {
        display: flex;
        background-color: $color-primary;
        margin-top: 10px;
        box-shadow: -2px 2px 10px rgba(0, 0, 0, .4);
        border-radius: 8px 0 0 8px;
        cursor: pointer;
        opacity: .8;
        // transform: translateX(0);
    }
}

.openRightMark {
    span {
        width: 0px;

        &:before {
            transform: translateX(-10px) rotateZ(45deg);
            width: 20px;
        }

        &:after {
            transform: translateX(-10px) rotateZ(-45deg);
            width: 20px;
        }
    }
}