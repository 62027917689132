@import '../../../../../styles/config.scss';

.root {
    display: flex;
    align-items: center;
    // overflow-x: auto;
    flex-wrap: wrap;
}

.left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .holder {
        margin-top: 10px;
    }

    .holder:not(:last-child) {
        margin-right: 16px;
    }
}



.search {
    border: 1px solid $color-grey;
    border-radius: 4px;
    overflow: hidden;
    height: $element_height;
    min-width: 250px;

    input {
        font-weight: normal;
        font-size: 14px;
    }
}

.btnFilterBlock {
    min-width: 200px;
}

.filterBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.filterBtnText {
    margin-left: 8px;
    color: $color-text_grey;
    font-size: 14px;
    font-weight: normal;
}