@import '/src//styles/config.scss';
.root {
    display: grid;
    grid-template-columns: auto minmax(200px,400px);
    // gap: 16px;
}

.left {
    padding: 24px;
    overflow-y: auto;
    height: 100vh;
}
.right {
    height: 100vh;
    overflow: auto;
}

.header{
    margin-bottom: 1.1em;
}