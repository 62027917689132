@import '/src/styles/config.scss';

.typeRow .cell {
    &:first-child {
        padding-left: 0;
    }
}

// .cell_sticky_1 {
//     position: sticky;
//     left: 0
// }
// .cell_sticky_2 {
//     position: sticky;
//     left: 120px
// }

.isCurrentTabel {
    border-top: 2px solid;
    border-bottom: 2px solid;
}

.innerDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 0;
    box-sizing: border-box;
    cursor: pointer;
}

.innerDivFirst {
    @extend .innerDiv;
    justify-content: flex-start;

    &:first-child {
        padding-left: 16px;
    }
}
.innerDivSecond {
    @extend .innerDiv;
    justify-content: flex-start;

}

.colorMark {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 16px;
}
.name {
    color: $color-text_grey;
}

.isWeekend {
    color: $color-primary;
    background-color: $color-grey-2 !important;
}

.isWeekendInner {
    background: $color-grey-2;
}

.isCurrentDay {
    border-left: 2px solid;
    border-right: 2px solid;
}

.input {
    display: flex;
    border: none;
    max-width: 30px;
    appearance: textfield;
    margin: 0; 
    background-color: transparent;
    color: $color-text_grey;
    text-align: center;
    cursor: pointer;
}

.inputOt {
    color: red;
}

.number{
    display: flex;
    align-items: center;
}