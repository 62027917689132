@import '../../../styles/config.scss';
.root {
    display: flex;
    align-items: center;
    border: 1px solid $color-grey;
    border-radius: 4px;
    width: 210px;
    height: 40px;
    box-sizing: border-box;
    justify-content: space-between;

    input {
        border: none;
        text-transform: capitalize;
        max-width: 100%;
        padding: 0;
        margin: 0;
        text-align: center;
        cursor: pointer;

        &:hover {
            color: $color-blue;
        }
    }
}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100%;
    cursor: pointer;
    transition: .3s;

    &:hover {
        background-color: $color-grey-2;
    }
    
}
.btn_prev {
    border-right: 1px solid $color-grey;
}
.btn_next {
    border-left: 1px solid $color-grey;
}
.prev {
    transform: rotate(90deg);
}
.next {
    transform: rotate(-90deg);
}

.date {
    display: flex;
    justify-content: center;
    padding: 0 10px;
    box-sizing: border-box;
    max-width: 120px;


}
