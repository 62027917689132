@import '/src/styles/config.scss';

.root {
    margin-top: 1.5em;

}

.item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8em;
}

.items{
    margin-top: 1.3em;
    &:not(:last-child){
        border-bottom: 1px solid $color-grey;
    }

}

.leftItem {
    width: 50%;
    color: $color-text_grey;
    font-weight: 300;
    // margin-bottom: 16px;
}
.rightItem{
    width: 50%;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexBlock {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 8px;
}

.isOpen {
    transform: rotate(180deg);
}
.cursorPointer {
    cursor: pointer;
}

.selector {
    min-width: 100px;
    padding: 0;
    justify-content: flex-start;

    div {
        padding-left: 0;
    }
}

.selectorLabel {
    margin-right: 10px;
}

/**
*Top
*/
.line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $color-grey;
}

.numberOfUsers {
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: $color-grey;
}

.checkedUsers {
    font-weight: 500;
    color: $color-black;
}

.tabels {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.3em;
    margin-bottom: 0.8em;
    gap: 5px 10px;
}

.tabel {
    padding: 5px 8px;
    border-radius: 12px;
    border: 1px solid rgba($color-text_grey, 0.5);
}
