@import '../../../../../styles/config.scss';

.root {
    width: 100%;
    flex: 1;
}

.button {
    height: 32px;
    border: none;
    color: $color-black;
    padding: 0 16px;
    font-size: 12px;

    &:hover {
        color: $color-black;
    }
}

.input {
    display: none;
}

.btnBlock {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button {
        margin-left: 16px;
    }
}