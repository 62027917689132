@import '/src/styles/config.scss';

.flexBlock {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    span {
        margin-right: 6px;
    }
}
.desc {
    transform: rotate(180deg);
}

.number {
    margin-left: 9px;
    cursor: default;
}
.headerRow {
    position: sticky;
    z-index: 5;
    top: 0;
    height: 40px;
    background-color: #fff;
}

.cell {
    vertical-align: middle;
    text-align: left;
    &:first-child {
        padding-left: 16px;
    }
    span {
        font-weight: 500;
    }
}
.cell_1 {
    min-width: 100px;
    max-width: 150px;
    
}
.cell_2 {
   
    width: 300px;

}
.cell_3 {
    max-width: 200px;
    min-width: 120px;
}
.cell_4 {
    max-width: 150px;
    min-width: 100px;
}
.cell_5 {
    
}