@import '../../../styles/config.scss';

.root {
    width: 100%;
    // border: 1px solid #E3E6EE;
    box-sizing: border-box;
    // border-radius: 2px;
    position: relative;
}

.error {

        border: 1px solid red;
   
}

.errorText {
    color: red;
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    left: 0;
}

.input {
    border: none;
    width: 100%;
    padding: 11px 40px 11px 16px;
    color: $color-text-grey;
    font-weight: 300;
    font-size: 16px;

    &:disabled {
        opacity: 1;
        background-color: #fff;
    }
}

.icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    display: flex;
    cursor: pointer;
}

.textarea {
    width: 100%;
    height: 150px;
    border: 1px solid $color-grey;
    border-radius: 4px;
    resize: none;
}
.label {
    display: block;
    margin-bottom: 5px;
    color: $color-text-grey;
}

// NOTES
.noteWrapper {
    position: absolute;
    z-index: 9999;
    left: 0;
    right: 0;
    width: 100%;
    top: calc(100% + 2px);
    max-height: 200px;
    overflow-y: auto;

}

.noteItem {
    padding: 13px 16px;
    background-color: #fff;
    border-bottom: 2px solid $color-grey;
    cursor: pointer;
    color: $color-text-grey;
    z-index: 99999;

    &:hover {
        background-color: rgb(241, 241, 241);
    }
}