@import '/src/styles/config.scss';

.root {
    width: 100%;
    position: absolute;
    background-color: #fff;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 0 0 8px rgba(0,0,0,.18);
}

.item {
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
        background-color: rgb(245, 245, 245);
    }

    &:not(:last-child){
        border-bottom: 1px solid $color-grey;
    }
}