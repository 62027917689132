@import 'message-like-antd/src/theme/message.css';
/*
=============================
Variables
=============================
*/

$menu-width: 80px;
$element-height: 40px;
$right-component: 400px;

/*
=============================
Breakpoints
=============================
*/

/*
=============================
Colors
=============================
*/

$color-font-color: #333333;
$color-primary: #E53B3B;
$color-background: #E5E5E5;
$color-grey: #E3E6EE;
$color-grey-2: #F1F3F7;
$color-light-blue: #E5F0FF;
$color-text-grey: #838383;
$color-white: #FFFFFF;
$color-orange: #FF7900;
$color-blue: #006AFB;
$color-yellow: #FFD100;
$color-green: #00E981;
$color-red: #FF003A;
$color-menu_bgc: #001529;
$color-black: #000000;


/*
=============================
functions
=============================
*/

/*
=============================
Mixins
=============================
*/
@mixin _desktop_big {
    @media (max-width: 1380px){
        @content;
    }
    
}
@mixin _desktop {
    @media (max-width: 1170px){
        @content;
    }
    
}
@mixin _tablet {
    @media (max-width: 767px){
        @content;
    }
    
}
@mixin _mobile {
    @media (max-width: 450px){
        @content;
    }
    
}

@mixin _land {
    @media (orientation: landscape){
        @content;
    }
}

