@import '/src/styles/config.scss';

.table {
    border-collapse: collapse;
    border: none;
    margin-bottom: 20px;
}
.root {
    padding: 1.2em;
}

.btnBlock {
    display: flex;
    button {
        margin-right: 16px;
    }
} 