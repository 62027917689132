@import '/src/styles/config.scss';

.modal {
    padding: 24px;
    width: 448px;
}
.header {
    display: flex;
    align-items: center;
}

.title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    color: #424242;
    margin-left: 18px;
}

.content {
    padding-left: 44px;
    color: #757575;
    font-size: 16px;
    margin-top: 8px;
    font-weight: 300;
    margin-bottom: 24px;
}
.btnBlock {
    display: flex;
    justify-content: flex-end;

    button {
        &:first-child {
            margin-right: 20px;
        }
    }
}
.btn {
    padding: auto;
    border-radius: 4px;
    height: 36px;
    transition: .2s;

}