@import '../../../../../styles/config.scss';

.root {
    width: 400px;
}
.date {
    margin-top: 16px;
}
.holder {
    margin-bottom: 8px;
}
.infoItem {
    display: flex;
    margin-bottom: 8px;
}
.info , .addInfo{
    margin-top: 16px;
}
.leftKey {
    color: $color-text_grey;
    width: 40%;
}

/**
*switch
*/
.switchHolder {
    margin-bottom: 10px;
}