@import '/src/styles/config.scss';

.modal {
    background-color: #F5F8FF;
    max-width: 700px;
    padding-top: 29px;

}
.modalBody {
    padding: 0 100px;
}
.content {
    width: 490px;
}
.holder {
    margin-bottom: 32px;
}
.btnBlock {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.btn {
    height: $element_height;
    border-radius: 4px;
    background-color: $color-light-blue;
    border-color: $color-blue;
    color: $color-blue;
    padding: 0 8px;
    &:hover {
        span {
            color: $color-blue;
        }
    }
}
.btnCancel {
    height: $element_height;
    border-color: $color-primary;
    color: $color-primary;
    margin-left: 16px;
    padding: 0 8px;
    border-radius: 4px;
}
.input {
    border-radius: 4px;
}
.checkbox {
    margin-bottom: 8px;
}