@import '../../../../../styles/config.scss';

.item {
    height: 40px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 16px;
    border: 1px solid #E3E6EE;
    cursor: pointer;
    transition: .2s;

    // &:hover {
    //     transform: scale(1.001);
    //     box-shadow: 0 0 8px rgba(0,0,0,.2);
    // }

    &:nth-child(odd) {
        background-color: $color-grey-2;
    }
    &:nth-child(even) {
        background-color: #fff;
    }

    input {
        border: none;
        background-color: transparent;
        background: transparent;
        width: 100%;
    }
}

.activeItem {
    border: 1px solid $color-blue;
}
.disableItem {
    opacity: .9;
}
.list {
    margin-top: 24px;
}

.button {
    height: 32px;
    border: none;
    color: $color-black;
    padding: 0 16px;
    font-size: 12px;

    &:hover {
        color: $color-black;
    }
}
.btnBlock {
    display: flex;
    margin-top: 16px;

    button {
        &:not(:first-child){
            margin-left: 16px;
        }
    }
}

.isEdit {
    border-bottom: 1px solid $color-black;
}