@import '/src/styles/config.scss';
.root {
    padding: 1.2em;
}

.top {
    margin-bottom: 16px;
}

.board {
    display: grid;
    grid-template-columns: 1fr 2fr 10fr;
    column-gap: 16px;
}

.docs {

}