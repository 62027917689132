@import '../../../../../../../styles/config.scss';

table {
    width: 100%;
}

thead{
    width: 100%;

    .cell {
        font-weight: 500;
    }
}
.docTable{
    width: 100%;
    border-collapse: collapse;
}
.docRow{
    height: 40px;
    padding: 13px 16px;
    border-collapse: collapse;
    border-bottom: 1px solid #E3E6EE;
    
}
.cell {
    text-align: start;
    vertical-align: middle;
    color: $color-text_grey;

    &:first-child {
        padding-left: 16px;
    }
}

// th {
//     font-weight: 500;
// }


.cell_1 {
    width: 40px;
     vertical-align: middle;
}
.cell_3 {

     vertical-align: middle;
}
.cell_2 {
    width: 80px;
     vertical-align: middle;
}
.cell_4 {
    text-align: end;
    padding-right: 16px;
    min-width: 100px;
     vertical-align: middle;

    svg {
        cursor: pointer;
        &:not(:last-child){
            margin-right: 12px;
            
        }
    }
}