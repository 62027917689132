@import '../../../styles/config.scss';

.check {
    position: relative;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid $color-grey;
    padding: 2px;
    box-sizing: border-box;
    overflow: hidden;
}

// .checkbox {
//     width: 16px;
//     height: 16px;
//     display: flex;
//     border-radius: 2px;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
//     background-color: #fff;
//     border: 1px solid $color-grey;
//     padding: 2px;
//     box-sizing: border-box;
// }

.check:hover {
    background-color: $color-grey-2;
}
.check.checked:hover  {
    background-color: $color-blue;
}
.check.checked{
    background-color: $color-blue;
}

.mark{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.root {
    display: flex;
    align-items: center;
}

.label {
    cursor: pointer;
    margin-left: 12px;
    white-space: nowrap;
}
