@import '/src/styles/config.scss';

.root {
    padding: 2em 1.5em;
    width: 384px;
    display: flex;
    flex-direction: column;
    // flex: 1;
    height: 100%;
}

.topWrapper {
    display: flex;
    justify-content: space-between;
}

.changeBtn {
    height: $element_height;
    border-radius: 4px;
    background-color: $color-light-blue;
    border-color: $color-blue;
    color: $color-blue;
    padding: 0 8px;

    &:hover {
        span {
            color: $color-blue;
        }
    }
}