@import '../../../styles/config';

.loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.4);
    z-index: 999999999999;
}

.loaderInner {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: 100%;
    color: $color-primary;
    padding: 0 100px;
    text-align: center;
}

.loaderInner label {
    font-size: 20px;
    opacity: 0;
    display: inline-block;
}

@keyframes lol {
    0% {
        opacity: 0;
        transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        transform: translateX(0px);
    }
    66% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(300px);
    }
}

@-webkit-keyframes lol {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    66% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(300px);
    }
}

.loaderInner label:nth-child(6) {
    -webkit-animation: lol 3s infinite ease-in-out;
    animation: lol 3s infinite ease-in-out;
}

.loaderInner label:nth-child(5) {
    -webkit-animation: lol 3s 100ms infinite ease-in-out;
    animation: lol 3s 100ms infinite ease-in-out;
}

.loaderInner label:nth-child(4) {
    -webkit-animation: lol 3s 200ms infinite ease-in-out;
    animation: lol 3s 200ms infinite ease-in-out;
}

.loaderInner label:nth-child(3) {
    -webkit-animation: lol 3s 300ms infinite ease-in-out;
    animation: lol 3s 300ms infinite ease-in-out;
}

.loaderInner label:nth-child(2) {
    -webkit-animation: lol 3s 400ms infinite ease-in-out;
    animation: lol 3s 400ms infinite ease-in-out;
}

.loaderInner label:nth-child(1) {
    -webkit-animation: lol 3s 500ms infinite ease-in-out;
    animation: lol 3s 500ms infinite ease-in-out;
}
