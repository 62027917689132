@import '/src/styles/config.scss';

.rowHeader {
    background-color: #fff;
    border-collapse: collapse;
    height: 56px;
}

.headerCell {
    vertical-align: middle;
}
.headerCell_1 {
    width: 150px;
    padding-left: 16px;
}
.headerCell_2 {
    width: 250px;

}
.headerCell_3 {
    width: 120px;

}
.headerCell_4 {
    width: 120px;

}
.headerCell_5 {
    width: 120px;

}
.headerCell_6 {
    width: 80px;

}
.headerCell_7 {
    text-align: left;

}
.smallSelector {
    min-width: 50px;
    width: 100%;
    padding: 0;
    
}

.smallSelectorLabel {
    // flex: 1;
    padding: 0;
    width: 60px;
}




// BODY

 tbody td.bodyCell {
    border-collapse: collapse;
    border: none;
}

.typeRow {
    border-top: 1px solid $color-grey;
    cursor: pointer;
}


.activeType {
    border: 1px solid $color-blue;
}



.nameInput {
    width: 90%;
    border: none;
    border-bottom: 1px solid $color-black;
}
.coeffInput {
    @extend .nameInput;
    text-align: center;
}

.bodyCell_7 {
    text-align: left;
}