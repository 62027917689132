@import '../../../styles/config.scss';

.select {
    border: 1px solid $color-grey;
    background: transparent;
    min-width: 220px;
    max-width: 600px;
    position: relative;
    padding: 0 30px 0 16px;
    height: $element-height;
    border-radius: 4px;
    display: flex;


    &:active {
        box-shadow: none;
    }
}

.label {
    padding: 0 0px;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: auto;
   
}

/**
*
*/
.extraWrapper {
    padding: 2px;
    position: absolute;
    left: 100%;
    top: 0;
}

.extraMenu {
    overflow-y: auto;
    overflow-x: hidden;
    width: 250px;
    max-height: 350px;
    background-color: #fff;
    box-shadow: 0 6px 5px rgba(0, 0, 0, 0.2);
    display: none;

    &:hover {
        display: block;
    }

}


.itemWrapper {
    // padding-right: 25px;
    position: relative;
    box-sizing: border-box;
    padding: 1px 25px 1px 1px;

    &:not(:last-child) {
        border-bottom: 1px solid $color-grey;
    }

    &:hover {
        padding: 0 24px 0 0;
        border: 1px solid $color-blue;

        .extraMenu {
            display: block;
        }
    }
}

.itemIcon {
    transform: rotate(-90deg) translateY(-50%);
    position: absolute;
    right: 10px;
    top: 50%;

}

.options {
    display: flex;
    align-items: center;

    position: relative;
    cursor: pointer;
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    padding: 8px 0;
    margin: 0 16px;



    &:not(:first-child) {
        margin-top: 10px;
    }


}

.checkbox {

        margin-right: 12px;

}

.drop {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgb(255, 255, 255);
    border-radius: 0 0 5px 5px;
    padding: 0 0;
    z-index: 10;
    box-shadow: 0 6px 5px rgba(0, 0, 0, 0.1);
    border-top: 1px solid $color-grey;
}

.oneLevel {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bgcOdd {
    background-color: $color-grey-2;
}

.topLabel {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.mark {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}

.activeMark {
    // transform: rotate(180deg);
}

.placeholder {
    color: #c1c1c1
}

.labelTop {
    display: block;
    margin-bottom: 5px;
    color: $color-text-grey;
    padding: 0;
}


//input
.inputBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 8px;
    border-bottom: 1px solid #333;
height: 44px;
position: sticky;
background-color: #fff;
z-index: 5;
top: 0;

    button {
        margin-top: 0;
    }

    input {
        border: none;
        width: 100%;
    }
}