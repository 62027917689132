@import '../../../styles/config.scss';

.root {
    display: flex;
    justify-content: flex-start;
}

.item {
    height: 72px;
    padding: 18px 32px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text_grey;
    font-size: 20px;
    opacity: .5;
    cursor: pointer;
    transition: .2s;
    &:hover {
        opacity: .75;
    }
    &:not(:last-child){
        margin-right: 16px;
        
    }
}

.active {
    opacity: 1;
    &:hover {
        opacity: 1;
    }
}