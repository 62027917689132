@import '/src/styles/config.scss';

.header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.search {
    border: 1px solid $color-grey;
    border-radius: 4px;
    overflow: hidden;
    height: $element_height;
    min-width: 400px;
    max-width: 420px;
    margin-left: 16px;

    input {
        font-weight: normal;
        font-size: 14px;
        
    }
    @include _desktop{
        margin-left: 0;
        max-width: 100%;
        min-width: 100%;
        flex: 1;
    }
}
.holder {
    margin-top: 16px;
    display: flex;
    align-items: center;
    @include _desktop{
        width: 100%;
    }
   
}
.filterBtn {

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
    cursor: pointer;
    .filterSvg {
        width: 19px;
        height: 16px;
    }
    .filterBtnText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #838383;
        margin-left: 8px;
        white-space: nowrap;
    }

    @include _desktop_big {
        margin: 0;
    }
}
.changeBtn {
    height: $element_height;
    border-radius: 4px;
    background-color: $color-light-blue;
    border-color: $color-blue;
    color: $color-blue;
    padding: 0 8px;
    &:hover {
        span {
            color: $color-blue;
        }
    }
}

.rightBlock {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    button {
        &:first-child{
            margin-right: 12px;
        }
    }
}
.leftPart {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.checkbox {
    margin-left: 16px;
}