@import '../../../../../styles/config.scss';

.tabelTable {
    background-color: #fff;
    border-collapse: separate;
}
.tabelTable td {
    &:first-child{
        position: sticky;
        left: 0;
    }
    &:nth-child(2){
        position: sticky;
        left: 120px;
    }
}
.tabelTable th {
    // position: sticky;
    // top: 0;
    // z-index: 9;
    &:first-child{
        position: sticky;
        left: 0;
    }
    &:nth-child(2){
        position: sticky;
        left: 120px;
    }
}
