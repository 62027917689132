@import '../../../styles/config.scss';

.modalParent {
    .root {
        display: none;

        &:last-child {
            display: flex;
        }
    }
}

.root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
}

.underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 100%;
    max-width: 39.33333rem;
    margin: auto;
    padding: 2.66667rem 0 16px;
    border-radius: 4px;
    max-height: 100%;
    z-index: 90000;

}

.title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}

.close {
    width: 100%;
    height: 15px;
    position: absolute;
    padding-right: 20px;
    padding-left: 20px;
    top: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.body {
    // overflow-y: auto;
    padding: 2px 16px;

    // overflow-y: auto;
    // min-height: 300px;
    // scroll-margin: 10px;
    // scrollbar-color: rgb(255, 37, 21) rgb(247, 241, 241);
    // scrollbar-width: thin;
    // padding-right: 10px;

    &:after {
        content: '.';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}