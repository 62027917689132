@import '../../../styles/config.scss';

.root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.bottom{
    flex:1 ;
    background-color: #fff;
}

.top {
    padding: 0.5em 2em;
    background: linear-gradient(0deg, rgba(227, 230, 238, 0.5), rgba(227, 230, 238, 0.5)), #FFFFFF;
}