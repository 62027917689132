@import '../../../../../styles/config.scss';

.item {
    height: 40px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 16px;
    border: 1px solid #E3E6EE;
    cursor: pointer;
    transition: .2s;

    // &:hover {
    //     transform: scale(1.001);
    //     box-shadow: 0 0 8px rgba(0,0,0,.2);
    // }

    &:nth-child(odd) {
        background-color: $color-grey-2;
    }
    &:nth-child(even) {
        background-color: #fff;
    }

  

    input {
        border: none;
        background-color: transparent;
        background: transparent;
        width: 300px;
    }

    span {
        width: 300px;
    }
}
.header {
    cursor: initial;
    margin-bottom: 10px;
    background-color: #fff;
    &:nth-child(odd) {
        background-color: #fff;
    }
    .first {
        cursor: pointer;
        width: 300px;
        font-weight: 500;
        color: $color-text_grey;

        span {
            margin-right: 10px;
        }
    }
    .second {
        padding-left: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
            width: 100%;
            margin-right: 10px;
            font-weight: 500;
            color: $color-text_grey;
        }
    }
}

.activeItem {
    border: 1px solid $color-blue;
    // box-shadow: 0 0 8px rgba(0,0,0,.2);
}

.isEdit {
    border-bottom: 1px solid $color-black;
}
.disableItem {
    opacity: .8;
}
.list {
    margin-top: 24px;
}

.button {
    height: 32px;
    border: none;
    color: $color-black;
    padding: 0 16px;
    font-size: 12px;
    margin-top: 0;

    &:hover {
        color: $color-black;
    }
}
.btnBlock {
    display: flex;
    margin-top: 16px;

    button {
        &:not(:first-child){
            margin-left: 16px;
        }
    }
}

.desc {
    transform: rotate(180deg);
}