@import '/src/styles/config.scss';

.root {
    display: grid;
    grid-template-columns: minmax(50%, auto) minmax(200px,330px);
    @media (min-width: 1720px) {
        grid-template-columns: minmax(50%, auto) minmax(200px,400px);
    }
    // gap: 16px;
}

.left {
    padding: 24px;
    height: 98vh;
    // overflow-y: auto;

}
.right {
    height: 100vh;
    overflow-y: auto;
}

.header{
    margin-bottom: 1.1em;
}

.table {
    overflow: auto;
    padding-bottom: 24px;
}

