@import '/src/styles/config.scss';

.button {
    margin-top: 24px;
    height: 32px;
    border: 1px solid $color-grey;
    color: $color-black;
    padding: 0 16px;
    font-size: 12px;
    width: 49%;

    &:hover {
        color: $color-black;
    }
}

.btnBlock {
    display: flex;
    justify-content: space-between;
}

.text {
    margin-top: 24px;
}