@import '../../../../../../../styles/config.scss';

.dayCell {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;

}

.weekend {
    color: $color-primary;
    background-color: $color-grey-2 !important;
}

.flexBlock {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    span {
        margin-right: 6px;
    }
}

tr, th {
    vertical-align: middle;
}

.row {
    // position: sticky;
    // z-index: 9;
    // top: 0;
    height: 40px;
    background-color: #fff;
}

.cell{
    border: 1px solid $color-background;
    background-color: #fff;
    min-width: 32px;
    vertical-align: middle;


    &:first-child {
        border-right: none;
        min-width: 120px;
        border-radius: 4px 0 0 0;
        vertical-align: middle;
        padding-left: 16px;
    }
    &:nth-child(2) {
        border-left: none;
        min-width: 200px;
        vertical-align: middle;
    }
    &:last-child {
        border-radius:  0 4px 0 0;
    }
}

.cell_stycky_day {
    // position: sticky;
    // height: 40px;
    // background-color: #fff;
    // z-index: 999999;
    // top: 0
}

// .cell_sticky_1 {
//     position: sticky;
//     left: 0
// }
// .cell_sticky_2 {
//     position: sticky;
//     left: 120px
// }


.desc {
    transform: rotate(180deg);
}

.isCurrentDay {

    // border-left: 2px solid !important;
    // border-right: 2px solid !important;
}

.dayOff {
    background-color: $color-primary;
    color: #fff
}
