@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Thin.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-Thin.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-ThinItalic.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Light.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-Light.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-LightItalic.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-LightItalic.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-Italic.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-Medium.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-MediumItalic.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-Bold.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-BoldItalic.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Black.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-Black.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2'),
        url('../assets/fonts/Roboto/Roboto-BlackItalic.woff') format('woff'),
        url('../assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
