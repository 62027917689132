@import '/src/styles/config.scss';


.root {
    display: flex;
    align-items: center;
}

.checkWrapper {
    width: 38px;
    height: 24px;
    background-color: #D2D2D2;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
}

.active {
    background-color: $color-blue;
}

.inner {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(2px);
    // left: 2px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 100%;
    transition: .3s;
}

.activeInner {
    left:initial;
    transform: translateY(-50%) translateX(16px);
    // right: 2px;
}

.label {
    font-size: 14px;
    margin-left: 9px;
}
